import axios from 'axios'

export default {
    namespaced: true,

    state: {
        application: sessionStorage.getItem('application'),
        applicationGrade: {},
        applicationSubjectChoice: {},
        applicationExisting: {},
        authenticated: sessionStorage.authenticated || false,
        schoolName: process.env.VUE_APP_THEME_SCHOOL,
        applicationDelete: {},
        applicationYear: null,
        applicationsLockStatus: 0,
    },

    getters: {
        getApplication (state) {
            return state.application
        },

        getApplicationGrade (state) {
            return state.applicationGrade
        },

        getApplicationSubjectChoice (state) {
            return state.applicationSubjectChoice
        },

        getApplicationExisting (state) {
            return state.applicationExisting
        },

        getAuthenticated (state) {
            return state.authenticated
        },

        getSchoolName (state) {
            return state.schoolName
        },

        getApplicationDelete (state) {
            return state.applicationDelete
        },

        getApplicationYear (state) {
            return state.applicationYear
        },

        getApplicationsLockStatus (state) {
            return state.applicationsLockStatus
        }
    },

    mutations: {
        setApplication (state, value) {
            state.application = value
        },

        setApplicationGrade (state, value) {
            state.applicationGrade = value
        },

        setApplicationSubjectChoice (state, value) {
            state.applicationSubjectChoice = value
        },

        setApplicationExisting (state, value) {
            state.applicationExisting = value
        },

        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setApplicationDelete (state, value) {
            state.applicationDelete = value
        },

        setApplicationYear (state, value) {
            state.applicationYear = value
        },

        setApplicationsLockStatus (state, value) {
            state.applicationsLockStatus = value
        }
    },

    actions: {
        getCSRFCookie () {
            return axios.get('/sanctum/csrf-cookie');
        },

        postApplication ({ getters }){
            const url = '/api/sixth-form-application';
            let application = getters.getApplication || {};
            return axios.post(url, application);
        },

        postApplicationGrade ({ getters }) {
            console.log(getters.getApplicationGrade)
            const url = '/api/sixth-form-application-grade';
            return axios.post(url, getters.getApplicationGrade);
        },

        postApplicationSubjectChoice ({ getters }) {
            const url = '/api/sixth-form-application-subject-choice';
            return axios.post(url, getters.getApplicationSubjectChoice);
        },

        getApplication ({ getters}) {
            const url = "/api/sixth-form-application/" +
            getters.getApplication.application_id + "/" +
            getters.getApplication.year;
            return axios.get(url);
        },

        getApplicationSubjectChoices ({ getters }) {
            const url = "/api/sixth-form-application-subject-choices/" +
            getters.getApplication.application_id + "/" +
            getters.getApplication.year;
            return axios.get(url);
        },

        getApplicationSubjectGrades ({ getters }) {
            const url = "/api/sixth-form-application-grades/" +
            getters.getApplication.application_id + "/" +
            getters.getApplication.year;
            return axios.get(url);
        },

        getSubjectLines () {
            const url = "/api/sixth-form-application-subject-lines";
            return axios.get(url);
        },

        getCsecSubjects () {
            const url = "/api/csec-subjects";
            return axios.get(url);
        },

        getApplicationExisting ({ getters }) {
            const url = "/api/sixth-form-application-check/" + 
            getters.getApplicationExisting.applicationId + "/" +
            getters.getApplicationExisting.birthCertificatePin;
            return axios.get(url);
        },

        adminSignIn ({ getters }) {
            return axios.post('/api/admin-login', {
                name: getters.getApplicationExisting.name,
                password: getters.getApplicationExisting.password
            })
        },

        getAllApplications () {
            const url = "/api/sixth-form-applications";
            return axios.get(url);
        },

        deleteApplication ({ getters }) {
            const url = "/api/sixth-form-application";
            return axios.delete(url, { data: getters.getApplicationDelete});
        },

        downloadAcceptedSpreadsheet ({ getters }) {
            const url = "/api/sixth-form-application-accepted-excel/" + getters.getApplicationYear;
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
            });
        },

        getApplicationsLockStatus ({ getters }) {
            const url = "/api/sixth-form-application-lock-status/" + getters.getApplicationYear;
            console.log(url);
            return axios.get(url);
        },

        postApplicationsLockStatus({ getters }) {
            const url = "/api/sixth-form-application-lock-status";
            return axios.post(url, {
                locked: getters.getApplicationsLockStatus,
                year: getters.getApplicationYear
            })
        },

        getCurrentApplicationPeriod () {
            const url = "/api/sixth-form-application-current-period";
            return axios.get(url);
        },

        getApplicationPeriods () {
            const url = "/api/sixth-form-application-periods";
            return axios.get(url);
        },

        adminSignOut () {
            return axios.post('/logout');
        },

        getInstructionsURL () {
            const url = "/api/sixth-form-application-instructions";
            return axios.get(url);
        },
    }
}